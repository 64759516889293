<template>
  <section class="detailRecord">
    <div class="detailRecord__headerInfo">
      <div class="detailRecord__contentItems">
        <p class="detailRecord__recordName">{{ record.name }}</p>
        <span class="detailRecord__recordCity" v-if="record.city">{{ record.city }}</span>
      </div>
      <div class="detailRecord__contentItems">
        <span class="detailRecord__detail">{{ `${age} años` }}</span>
        <span class="detailRecord__detail">{{ hasExperience(record.hasWebcam) }}</span>
      </div>
    </div>
    <div class="detailRecord__contentItems">
      <div class="relative" v-for="contactMode in contactModes" :key="contactMode.name">
        <iconic
          :class="`detailRecord__iconContact detailRecord__iconContact--${contactMode.icon}`"
          :name="contactMode.icon"
          @click.native.stop="contactMode.func(contactMode.value, contactMode.icon)"
        />
        <span class="detailRecord__tootip" v-if="keyCopied === contactMode.icon">Copiado</span>
      </div>
      <FeatureButtons :record="record" />
    </div>
    <span class="detailRecord__registerDate">{{ `Fecha registro: ${formatDate(record.date)}` }}</span>
  </section>
</template>

<script>
export default {
  props: ["record"],
  components: {
    FeatureButtons: () => import("../mainRecords/FeatureButtons.vue"),
  },
  data() {
    return {
      keyCopied: "",
      timeOutCopied: null,
    };
  },
  computed: {
    contactModes() {
      const dicModes = {
        phone: {
          name: "teléfono",
          func: this.copyTextToClipboard,
          icon: "phone",
        },
        whatsapp: {
          name: "whatsapp",
          func: this.openWhatsapp,
          icon: "whatsapp",
        },
        email: {
          name: "correo",
          func: this.copyTextToClipboard,
          icon: "card_email",
        },
      };
      const modesMap = [];
      const modes = (this.record?.contactMode || []).includes("all") ? ["phone", "whatsapp", "email"] : this.record?.contactMode || [];

      modes.forEach((contactMode) => {
        const valCompare = contactMode === "whatsapp" ? "phone" : contactMode;
        if (this.record?.[valCompare]) {
          modesMap.push({
            ...dicModes[contactMode],
            value: `${valCompare === "phone" ? `+${this.record.inputPostalCode} ` : ""}${this.record[valCompare]}`,
          });
        }
      });

      return modesMap;
    },
    features() {
      const dicDates = {
        priority: { name: "Prioridad", value: "high" },
        satelite: { name: "Satélite", value: "satelite" },
        gender: { name: "Masculino", value: "male" },
      };

      return Object.entries(dicDates).map(([key, { name, value }]) => ({
        key,
        name,
        value,
        selected: this.record[key] === value,
      }));
    },
    age() {
      const birthDayDate = this.record.birthDayDate;
      if (!birthDayDate) {
        return "";
      }
      const birthDateObject = new Date(birthDayDate);

      if (isNaN(birthDateObject)) {
        return "Sin edad";
      }

      const currentDate = new Date();
      const timeDifferenceInMilliseconds = currentDate - birthDateObject;
      const ageInMilliseconds = new Date(timeDifferenceInMilliseconds);
      const age = Math.abs(ageInMilliseconds.getUTCFullYear() - 1970);

      return age;
    },
  },
  methods: {
    hasExperience(val) {
      return `${val && val === "yes" ? "Con" : "Sin"} experiencia`;
    },
    copyTextToClipboard(text, showCopied) {
      if (!text) {
        return;
      }

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log("Texto copiado al portapapeles.");
          })
          .catch((error) => {
            console.error("Error al copiar al portapapeles:", error);
          });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.select();

        try {
          document.execCommand("copy");
          console.log("Texto copiado al portapapeles.");

          this.keyCopied = showCopied;

          clearTimeout(this.timeOutCopied);
          this.timeOutCopied = setTimeout(() => {
            this.keyCopied = "";
          }, 1000);
        } catch (error) {
          console.error("Error al copiar al portapapeles:", error);
        } finally {
          document.body.removeChild(textArea);
        }
      }
    },
    openWhatsapp(number) {
      if (!number) {
        return;
      }
      const cleanNumber = number.replace(/[+ ]/g, "").replace(/^0+/, "");
      const whatsappURL = `https://wa.me/${cleanNumber}`;

      window.open(whatsappURL, "_blank");
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      const dateParts = new Date(date).toLocaleString("es-CO", { dateStyle: "long", timeStyle: "short" }).split("de");
      dateParts.splice(2, 0, "de");

      return dateParts.join("");
    },
  },
};
</script>

<style lang="scss">
.detailRecord {
  @include Flex(column, space-between, flex-start);
  gap: 8px;
  width: 100%;
  height: 100%;
  min-height: 150px;
  &__headerInfo {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: 8px;
  }
  &__contentItems {
    @include Flex(row, flex-start);
    flex-wrap: wrap;
    gap: 10px;
  }
  &__recordName {
    font-weight: 600;
  }
  &__recordCity {
    @include Flex(row);
    height: 23px;
    padding: 0 10px;
    text-transform: capitalize;
    font-size: 14px;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 17px;
  }
  &__detail {
    @include Flex(row);
    height: 18px;
    padding: 0 10px;
    font-size: 15px;
    color: white;
    background: #767676;
    border-radius: 50px;
  }
  &__iconContact {
    padding: 0 2px;
    color: #5b5957;
    font-size: 20px;
    cursor: pointer;
    &--card_email {
      font-size: 22px;
    }
  }
  &__tootip {
    position: absolute;
    left: -70%;
    bottom: 135%;
    padding: 5px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    background-color: black;
    box-shadow: $dshadow;
    border-radius: 6px;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
  &__registerDate {
    font-size: 12px;
  }
}
</style>
